import Cookies from 'js-cookie';
import React from 'react';
import { SITE_DOMAIN } from '../constants';
import { DataLayer } from '../types/globals';
import { flatten } from './nodash';

export function withDataLayer(func: (dataLayer: DataLayer) => void): void {
  if (process.env.NODE_ENV === 'development') {
    const mockDataLayer = [];
    const origPush = mockDataLayer.push;
    mockDataLayer.push = (...data) => {
      console.log('Pushing data to dataLayer: ', ...data);
      return origPush.call(mockDataLayer, ...data);
    };
    func(mockDataLayer);
  }

  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    func(window.dataLayer);
  }
}

export function getUtmQueryStringFromUtmParams(
  utmSource: string | null,
  utmMedium: string | null,
  utmCampaign: string | null,
  utmTerm: string | null,
  utmContent: string | null,
) {
  const utmQuery = [
    utmSource ? 'utm_source=' + utmSource : '',
    utmMedium ? 'utm_medium=' + utmMedium : '',
    utmCampaign ? 'utm_campaign=' + utmCampaign : '',
    utmTerm ? 'utm_term=' + utmTerm : '',
    utmContent ? 'utm_content=' + utmContent : '',
  ]
    .filter(Boolean)
    .join('&');

  return utmQuery;
}

export const clsx = (...args: Array<string | false | null | undefined>): string =>
  args.filter(Boolean).join(' ');

export function setCookie(
  name: string,
  value: string,
  {
    expires = 365,
    sameSite = 'none',
    ...extraOptions
  }: {
    expires?: number;
    sameSite?: 'strict' | 'lax' | 'none';
  },
): void {
  const secure = location ? location.protocol === 'https:' : true;

  const cookieOptions = { expires, sameSite, secure, ...extraOptions };

  // Fallback for older browsers where can not set SameSite=None, SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
  if (sameSite === 'none') {
    Cookies.set(name + '-legacy', value, cookieOptions);
  }

  // set the regular cookie
  Cookies.set(name, value, cookieOptions);
}

export function getCookieValue(name: string): string | undefined {
  let cookieValue = Cookies.get(name);

  // if the cookieValue is undefined check for the legacy cookie
  if (cookieValue === undefined) {
    cookieValue = Cookies.get(name + '-legacy');
  }
  return cookieValue;
}

export function isDeviceMobile(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }

  const mobileRegex = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/;
  return (
    mobileRegex.test(window.navigator.appVersion) || mobileRegex.test(window.navigator.userAgent)
  );
}

export function slugify(str: string): string {
  return str
    .replace(/\s/gi, '-')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export function truncateText(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }
  const strParts = str.match(/(^|[^A-zÀ-ú\-])+[A-zÀ-ú\-]+/g) || [];
  let truncateStr = '';
  for (const strPart of strParts) {
    if (truncateStr.length + strPart.length + 3 > maxLength) {
      break;
    }
    truncateStr += strPart;
  }
  return truncateStr + '...';
}

export function convertRToSuperscript(str: string): React.ReactElement {
  const strParts = str.split(/(®)/).filter(Boolean);
  const convertedStrParts: Array<React.ReactElement | string> = [];
  for (let i = 0; i < strParts.length; i++) {
    const part = strParts[i];
    if (part === '®') {
      convertedStrParts.push(<sup key={i}>®</sup>);
    } else {
      convertedStrParts.push(part);
    }
  }
  return <>{convertedStrParts}</>;
}

export async function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export type StrPartPreprocesser = (str: string) => React.ReactNode;

export function wrapStrPartsBySplitter(
  str: string,
  splitter: string | RegExp,
  wrapStrPart: (strPart: React.ReactNode, index: number) => React.ReactNode,
  preprocessStrPart: StrPartPreprocesser = str => str,
): React.ReactNode {
  const strParts = str.split(splitter).map(strPart => preprocessStrPart(strPart));
  if (strParts.length % 2 === 0) {
    throw new Error(
      `String incorrectly formatted, got even number of parts after split. splitRegex: ${splitter}   str: ${str}`,
    );
  }
  return strParts.map((string, i) => (i % 2 !== 0 ? wrapStrPart(string, i) : string));
}

export function replaceSplitterWithEl(
  str: string,
  splitter: string | RegExp,
  el: React.ReactNode,
  preprocessStrPart: StrPartPreprocesser = str => str,
): React.ReactNode {
  const splitStr = str.split(splitter).map(strPart => preprocessStrPart(strPart));
  const splitStrWithEls: Array<React.ReactNode> = [];
  for (let i = 0; i < splitStr.length; i++) {
    splitStrWithEls.push(<React.Fragment key={i * 2}>{splitStr[i]}</React.Fragment>);
    splitStrWithEls.push(<React.Fragment key={i * 2 + 1}>{el}</React.Fragment>);
  }
  splitStrWithEls.pop();
  return splitStrWithEls;
}

export function wrapCurlyBracedInNoBreak(
  str: string,
  preprocessStrPart: StrPartPreprocesser = str => str,
) {
  return wrapStrPartsBySplitter(
    str,
    /[{}]/,
    (el, i) => (
      <span key={i} style={{ whiteSpace: 'nowrap' }}>
        {el}
      </span>
    ),
    preprocessStrPart,
  );
}

export function wrapCurlyBracedInSpan(
  str: string,
  preprocessStrPart: StrPartPreprocesser = str => str,
) {
  return wrapStrPartsBySplitter(
    str,
    /[{}]/,
    (el, i) => <span key={i}>{el}</span>,
    preprocessStrPart,
  );
}

export function wrapSquareBracketedWithEm(
  str: string,
  preprocessStrPart: StrPartPreprocesser = str => str,
) {
  return wrapStrPartsBySplitter(str, /[\[\]]/, (el, i) => <em key={i}>{el}</em>, preprocessStrPart);
}

export function wrapSquareBracketedWithEmAndSpan(
  str: string,
  preprocessStrPart: StrPartPreprocesser = str => str,
) {
  return wrapStrPartsBySplitter(
    str,
    /[\[\]]/,
    (el, i) => (
      <em key={i}>
        <span>{el}</span>
      </em>
    ),
    preprocessStrPart,
  );
}

export function removeParamsFromQueryParams(paramsToRemove: Array<string>) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlSearchParamsWithParamsRemoved = new URLSearchParams(urlSearchParams);
  for (const paramToRemove of paramsToRemove) {
    urlSearchParamsWithParamsRemoved.delete(paramToRemove);
  }
  const newQuery = urlSearchParamsWithParamsRemoved.toString();
  const newUrlPathAndQuery = window.location.pathname + (newQuery ? '?' + newQuery : '');
  history.replaceState(null, '', newUrlPathAndQuery);
}

// TODO Fix types
export type SanityTypename = 'SanityPage';

export function getReferenceUrl(typeName: SanityTypename, slug: string): string {
  switch (typeName) {
    case 'SanityPage':
      return urlJoin('/', slug);
    default:
      throw new Error('typeName value not known: ' + typeName);
  }
}

type UrlJoinOptions = { leadingSlash?: boolean; trailingSlash?: boolean };
/**
 * Joins url parts trying turn them into a valid url.
 *
 * Options (last param):
 * - leadingSlash, only useful when url has no protocol (default true):
 *      If true, forces url to start with leading slash.
 *      If false, forces url to start without leading slash.
 * - trailingSlash (default true):
 *      If true, forces url to end with trailing slash.
 *      If false, forces url to end without trailing slash.
 *
 * Examples:
 * CALL: urlJoin()
 * RESULT: ''
 *
 * CALL: urlJoin('/')
 * RESULT: '/'
 *
 * CALL: urlJoin('///')
 * RESULT: '/'
 *
 * CALL: urlJoin('a')
 * RESULT: '/a/'
 *
 * CALL: urlJoin('a', { leadingSlash: false })
 * RESULT: 'a/'
 *
 * CALL: urlJoin('a', { trailingSlash: false })
 * RESULT: '/a'
 *
 * CALL: urlJoin('/a/')
 * RESULT: '/a/'
 *
 * CALL: urlJoin('/a/', { leadingSlash: false })
 * RESULT: 'a/'
 *
 * CALL: urlJoin('/a/', { trailingSlash: false })
 * RESULT: '/a'
 *
 * CALL: urlJoin('/a', '?b=c')
 * RESULT: '/a/?b=c'
 *
 * CALL: urlJoin('/a', 'b=c')
 * RESULT: '/a/?b=c'
 *
 * CALL: urlJoin('/a/', '?b=c')
 * RESULT: '/a/?b=c'
 *
 * CALL: urlJoin('/a/', 'b=c')
 * RESULT: '/a/?b=c'
 *
 * CALL: urlJoin('//a/', '?b=c')
 * RESULT: '/a/?b=c'
 *
 * CALL: urlJoin('/a', '?b=c', { trailingSlash: false })
 * RESULT: '/a?b=c'
 *
 * CALL: urlJoin('/a/', '?b=c', { trailingSlash: false })
 * RESULT: '/a?b=c'
 *
 * CALL: urlJoin('/a/', 'b=c', { trailingSlash: false })
 * RESULT: '/a?b=c'
 *
 * CALL: urlJoin('/a/', 'b&d')
 * RESULT: '/a/?b&d'
 *
 * CALL: urlJoin('/a/', 'b=&d')
 * RESULT: '/a/?b=&d'
 *
 * CALL: urlJoin('/a/', 'b=c&d=')
 * RESULT: '/a/?b=c&d='
 *
 * CALL: urlJoin('/a/', 'b=c&d=e')
 * RESULT: '/a/?b=c&d=e'
 *
 * CALL: urlJoin('http://www.google.com/a//b/cd/e/f///gh/ijk///?foo=123', '?bar=baz', '&xpto=poiu')
 * RESULT: 'http://www.google.com/a/b/cd/e/f/gh/ijk/?foo=123&bar=baz&xpto=poiu'
 *
 * CALL: urlJoin('http://www.google.com/', '/a/', '/b/cd/', 'e/f', '///gh/ijk///', '?foo=123', '?bar=baz', '&xpto=poiu')
 * RESULT: 'http://www.google.com/a/b/cd/e/f/gh/ijk/?foo=123&bar=baz&xpto=poiu'
 *
 * CALL: urlJoin('http:', '//www.google.com/', '/a/', '#xpto')
 * RESULT: 'http://www.google.com/a/#xpto'
 *
 * CALL: urlJoin('http:', '//www.google.com/', '/a/', '#xpto', { trailingSlash: false })
 * RESULT: 'http://www.google.com/a#xpto'
 *
 * CALL: urlJoin('http://www.google.com/', '/a/', '/b/cd/', 'e/f', '///gh/ijk///', '?foo=123', '?bar=baz', '&xpto=poiu', { leadingSlash: false, trailingSlash: false})
 * RESULT: 'http://www.google.com/a/b/cd/e/f/gh/ijk?foo=123&bar=baz&xpto=poiu'
 *
 * CALL: urlJoin(' https://www.google.com/ ', '  /a/', '  /b/cd/ ', '  e/f  ', ' ///gh/ijk///  ', '    ?foo=123 ', ' ?bar=baz ', ' &xpto=poiu   ', '  alpha=beta&gamma=delta& ', '  ?kilo=mega?giga=tera? ', ' #some-hash ')
 * RESULT: 'https://www.google.com/a/b/cd/e/f/gh/ijk/?foo=123&bar=baz&xpto=poiu&alpha=beta&gamma=delta&kilo=mega&giga=tera#some-hash'
 *
 * CALL: urlJoin(' file:', 'www.google.com/ ', '  /a/', '  /b/cd/ ', '  e/f  ', ' ///gh/ijk///  ', '    ?foo=123 ', ' ?bar=baz ', ' &xpto=poiu   ', '  alpha=beta&gamma=delta& ', '  ?kilo=mega?giga=tera? ', ' #some-hash ')
 * RESULT: 'file:///www.google.com/a/b/cd/e/f/gh/ijk/?foo=123&bar=baz&xpto=poiu&alpha=beta&gamma=delta&kilo=mega&giga=tera#some-hash'
 */
export function urlJoin(...urlParts: Array<string> | [...Array<string>, UrlJoinOptions]): string {
  let options: UrlJoinOptions = {
    leadingSlash: true,
    trailingSlash: true,
  };
  let cleanUrlParts: Array<string>;
  if (typeof urlParts[urlParts.length - 1] === 'object') {
    options = {
      ...options,
      ...(urlParts[urlParts.length - 1] as UrlJoinOptions),
    };
    cleanUrlParts = urlParts.slice(0, -1) as Array<string>;
  } else {
    cleanUrlParts = urlParts as Array<string>;
  }
  if (options.leadingSlash === undefined) {
    options.leadingSlash = true;
  }
  if (options.trailingSlash === undefined) {
    options.trailingSlash = true;
  }

  if (cleanUrlParts.some(str => typeof str !== 'string')) {
    throw new TypeError('Url parts must be a strings. Received ' + JSON.stringify(cleanUrlParts));
  }
  cleanUrlParts = [...cleanUrlParts].map(str => str.trim());

  if (cleanUrlParts.length === 0) {
    return '';
  }

  // Add '?' to beginning of parts that are query params (have '=' or '&')
  // ensuring the state machine will correctly treat them as query params
  cleanUrlParts = cleanUrlParts.map(urlPart =>
    (urlPart.includes('&') || urlPart.includes('=')) &&
    urlPart.match(/^\w+(=(\w+)?)?(&\w+(=(\w+)?)?)*$/)
      ? '?' + urlPart
      : urlPart,
  );

  // Split by special chars and remove empty strings
  cleanUrlParts = flatten(cleanUrlParts.map(urlPart => urlPart.split(/([/#?&])/))).filter(Boolean);

  let beforeParams = true;
  let beforeHash = true;
  let lastPartWasSpecialChar = true;

  const finalUrlParts: Array<string> = [];

  // state machine that puts the parts in finalUrlParts by removing duplicate special chars,
  // ensuring correct usage of ? and &, and putting everything after # without touching it
  for (let i = 0; i < cleanUrlParts.length; i++) {
    const urlPart = cleanUrlParts[i];
    if (beforeParams) {
      if (urlPart === '#') {
        beforeParams = false;
        beforeHash = false;
        finalUrlParts.push('#');
        lastPartWasSpecialChar = true;
      } else if (urlPart === '?' || urlPart === '&') {
        beforeParams = false;
        finalUrlParts.push('?');
        lastPartWasSpecialChar = true;
      } else if (urlPart === '/') {
        if (!lastPartWasSpecialChar) {
          finalUrlParts.push('/');
        }
        lastPartWasSpecialChar = true;
      } else {
        if (!lastPartWasSpecialChar) {
          finalUrlParts.push('/');
        }
        finalUrlParts.push(urlPart);
        lastPartWasSpecialChar = false;
      }
    } else if (beforeHash) {
      if (urlPart === '#') {
        beforeHash = false;
        finalUrlParts.push(urlPart);
        lastPartWasSpecialChar = true;
      } else if (urlPart === '?' || urlPart === '&') {
        if (!lastPartWasSpecialChar) {
          finalUrlParts.push('&');
        }
        lastPartWasSpecialChar = true;
      } else {
        if (!lastPartWasSpecialChar) {
          finalUrlParts.push('&');
        }
        finalUrlParts.push(urlPart);
        lastPartWasSpecialChar = false;
      }
    } else {
      finalUrlParts.push(urlPart);
    }
  }

  if (finalUrlParts.length === 0) {
    finalUrlParts.push('/');
  }

  // Add missing slashes to protocol
  if (finalUrlParts[0].endsWith(':')) {
    finalUrlParts[0] = finalUrlParts[0] === 'file:' ? 'file://' : finalUrlParts[0] + '/';
  }

  let finalStr = finalUrlParts.join('');

  // Remove double special chars, we'll add the slashes later if needed
  finalStr = finalStr.replace(/\/\?/, '?');
  finalStr = finalStr.replace(/\/#/, '#');
  finalStr = finalStr.replace(/&#/, '#');

  // Only look at leadingSlash option if there is no protocol
  if (!finalStr.match(/^([^/:]+):\//)) {
    if (options.leadingSlash) {
      if (!finalStr.startsWith('/')) {
        finalStr = '/' + finalStr;
      }
    } else {
      if (finalStr.startsWith('/')) {
        finalStr = finalStr.substring(1);
      }
    }
  }

  if (options.trailingSlash) {
    if (finalStr.includes('?')) {
      finalStr = finalStr.split('?').join('/?');
    } else if (finalStr.includes('#')) {
      finalStr = finalStr.split('#').join('/#');
    } else if (!finalStr.endsWith('/')) {
      finalStr = finalStr + '/';
    }
  } else {
    if (!finalStr.includes('?') && !finalStr.includes('#') && finalStr.endsWith('/')) {
      finalStr = finalStr.substring(0, finalStr.length - 1);
    }
  }

  return finalStr;
}

/**
 * Generates a unique id (numeric as a string) by combining the current timestamp with a random number.
 */
export function generateUniqueId(): string {
  const timestamp = new Date().getTime();
  const randomNumber = Math.floor(Math.random() * 1000000);
  return timestamp.toString() + randomNumber.toString();
}

/**
 * Looks up a string value in localStorage by the given key. If the key is found,
 * returns the stored value. If not, generates a new value by invoking the provided
 * function, stores it under the specified key, and returns the generated value.
 *
 * @param {string} key - The key to use for lookup in localStorage.
 * @param {Function} generateValueFn - A function that generates a new string value.
 * @returns {string} - The value found in localStorage or the newly-generated value.
 */
export function getLocalStorageMemoizedValue(key: string, generateValue: () => string): string {
  const storedValue = window.localStorage.getItem(key);

  if (storedValue) {
    return storedValue;
  } else {
    const generatedValue = generateValue();
    window.localStorage.setItem(key, generatedValue);
    return generatedValue;
  }
}

export function checkIsInternalUrl(url: string): boolean {
  if (url.match(new RegExp(`^https?://(?:www\\.)?${SITE_DOMAIN}(?:\/|$)`))) {
    return true;
  } else if (url.match(/^\w+:\/\//)) {
    return false;
  } else if (url.match(/^(tel|fax|mailto):/)) {
    return false;
  } else {
    return true;
  }
}

export function getInternalUrlPath(url: string): string {
  if (!checkIsInternalUrl(url)) {
    throw new Error('Called getInternalUrlPath with a non internal url: ' + url);
  }
  if (!url.startsWith('http')) {
    return url;
  }
  const match = url.match(new RegExp(`^https?://(?:www\\.)?${SITE_DOMAIN}(.*)`));
  if (match === null) {
    throw new Error('Got null match from supposedly internal url: ' + url);
  }
  const path = match[1];
  if (path === '') {
    return '/';
  }
  if (!path.startsWith('/')) {
    throw new Error("Url path should start with slash but doesn't: " + url);
  }

  return path;
}
